import { WbText } from '@workbench/react';
import { useTranslation } from 'react-i18next';
import VideoJS from '../Common/VideoJS/VideoJS';
import RDBox from '../Common/RDBox/RDBox';

function GettingStartedVideo(props) {
	const { className } = props;
	const { t, i18n } = useTranslation();

	const sourceUrl = `assets/retailer-dashboard-${
		i18n.language === 'de' ? 'de' : 'en'
	}.mp4`;

	return (
		<RDBox className={`gs-example ${className}`} direction="vertical">
			<div className="gs-example__video getting-started__video">
				<VideoJS
					sources={[
						{
							src: sourceUrl,
							type: 'video/mp4',
						},
					]}
				/>
			</div>
			<div className="gs-example-text">
				<WbText strong>{t("mainScreen.videoHomepageCta")}</WbText>
			</div>
		</RDBox>
	);
}

export default GettingStartedVideo;
